import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BilikuHeader from "./ui-components/BilikuHeader";
import BilikuFooter from "./ui-components/BilikuFooter";
import Header from "./ui-components/NavBarHeader";
import Footer from "./ui-components/MarketingFooter";
import HomePage from "./HomePage";

const AppContent = () => {
  return (
    <div>
      <Header width={"100 vw"} />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer width={"100 vw"} />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
