import Header from "./ui-components/NavBarHeader";

function HomePage() {
  return (
    <div>
      Home Page
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.6427132888775!2d111.83006317457487!3d2.286142297693821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31f77fdfd1813f5f%3A0x95d15b7923473be4!2z56CC5ouJ6LaK6K-X5ber5YWs5pWZ5Lit5a2m!5e0!3m2!1szh-CN!2smy!4v1719741109526!5m2!1szh-CN!2smy"
        width="100%"
        height="500"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="Google Maps Location" // 添加一个唯一的 title 属性
      ></iframe>
    </div>
  );
}

export default HomePage;
